import {
  VStack,
  useResponsive,
  Image,
  getCdnImageSrc,
  ColorIcon,
  colors,
  VLine,
  HStack,
  responsive,
} from "goi_common";
import { Box, Stack } from "@chakra-ui/react";
import { ReactNode } from "react";
import { MAX_WIDTH } from "@/constants/components";

export default function AboutSection() {
  const { isBiggerThanLg } = useResponsive();
  return (
    <VStack width="100%" alignItems="center">
      <VStack
        maxWidth={`${MAX_WIDTH}px`}
        gap={isBiggerThanLg ? "120px" : "40px"}
        css={responsive({
          padding: { base: "0 24px 64px", lg: "0px 24px 120px" },
        })}
      >
        <AboutItem
          num={1}
          firstContent={{
            title: (
              <Box color={colors.warmGray450}>
                언젠가 꼭 필요한 장례, <br />
                <b className="white">잦은 상조의 폐업</b>으로 인해 <br />
                <b className="white">불합리한 소비자 피해가 반복</b>되었어요.
              </Box>
            ),
            description:
              "2022년 상조 피해 금액만 해도 690억.\n상조회사의 폐업으로 몇백억 원 대의 \n소비자 피해가 반복되었습니다.",
          }}
          secondContent={{
            title: (
              <Box>
                고이는 고객의 돈을
                <br />
                100% 안전하게 보관해요.
              </Box>
            ),
            description:
              "고이는 제 1 금융권인 국민은행과의 예치 계약을 통해\n납입금을 100% 안전하게 보관하고 있습니다.",
          }}
        />
        <VLine
          color={colors.warmGray800}
          width="100%"
          height="1px"
          margin="20px 0"
        />

        <AboutItem
          num={2}
          firstContent={{
            title: (
              <Box color={colors.warmGray450}>
                상조상품 주의보
                <br />
                <b className="white">결합상품 주의보 알고 계셨나요?</b>
              </Box>
            ),
            description:
              "중도 해지시 모집수당, 관리비, 사은품 대금 등을 공제하여\n해지환급금이 지나치게 낮은 문제가 지적되고 있습니다.",
          }}
          secondContent={{
            title: (
              <Box>
                고이는 중도 해지 시<br />
                100% 환급해 드려요.
              </Box>
            ),
            description:
              "고이는 복잡한 산식 대신 누적 납입금 전액을\n전액 투명하게 환급해 드립니다.",
          }}
        />
        <VLine
          color={colors.warmGray800}
          width="100%"
          height="1px"
          margin="20px 0"
        />
        <AboutMobileBackgroundItem
          num={3}
          firstContent={{
            title: (
              <Box color={colors.warmGray450}>
                불합리한 장례 관행
                <br />
                <span className="white">노잣돈 유도, 과도한 추가금 결제</span>
              </Box>
            ),
            description:
              "처음 약속한 금액보다 실제 지불하는 비용이 높고,\n서비스 품질은 형편없는 장례가 반복되고 있습니다.",
          }}
          secondContent={{
            title: (
              <Box>
                고이는 투명하고 정직한 장례를 위한
                <br />
                3가지 약속을 지키고 있어요.
              </Box>
            ),
            description: (
              <VStack gap={isBiggerThanLg ? "16px" : "9px"}>
                <AboutBadgeDescription
                  num={1}
                  description="업계 최초 품목별 가격 정찰제"
                />
                <AboutBadgeDescription
                  num={2}
                  description="미 사용품 100% 현금 공제"
                />
                <AboutBadgeDescription
                  num={3}
                  description="서비스 불만족시 100% 환불 정책"
                />
              </VStack>
            ),
          }}
        />
      </VStack>
    </VStack>
  );
}

function AboutFirstContentItem({
  num,
  title,
  description,
}: {
  num: number;
  title: ReactNode;
  description: string;
}) {
  const { isBiggerThanLg } = useResponsive();
  return (
    <Stack
      gap="16px"
      alignItems="center"
      justifyContent="space-between"
      css={responsive({
        flexDirection: { base: "column", lg: "row" },
      })}
    >
      <VStack
        justifyContent="center"
        css={responsive({
          width: { base: "100%", lg: "50%" },
        })}
      >
        <ColorIcon
          name="icon-warning-triangle-mono"
          size={36}
          color={colors.gray600}
        />
        <Box
          className="subtitle_21_eb lg_head_32_b"
          css={responsive({
            padding: { base: "16px 0 0", lg: "16px 0 40px" },
          })}
        >
          {title}
        </Box>
        {isBiggerThanLg && (
          <Box
            className="gray400 body_14_r lg_subtitle_21_r"
            css={{ wordBreak: "keep-all", whiteSpace: "pre-line" }}
          >
            {description}
          </Box>
        )}
      </VStack>
      <VStack
        borderRadius="16px"
        css={responsive({
          width: { base: "100%", lg: "50%" },
          height: "100%",
        })}
      >
        <Image
          src={getCdnImageSrc(`direct/home/about-pr-${num}.png`)}
          width="100%"
          height="auto"
          alt=""
        />
      </VStack>
      {isBiggerThanLg || (
        <Box
          className={
            isBiggerThanLg ? "gray400 subtitle_21_r" : "gray400 body_14_r"
          }
          width="100%"
          css={{ wordBreak: "keep-all", whiteSpace: "pre-line" }}
        >
          {description}
        </Box>
      )}
    </Stack>
  );
}

function AboutItem({
  num,
  firstContent,
  secondContent,
}: {
  num: number;
  firstContent: { title: ReactNode; description: string };
  secondContent: { title: ReactNode; description: string };
}) {
  return (
    <VStack gap="64px">
      <AboutFirstContentItem
        num={num}
        title={firstContent.title}
        description={firstContent.description}
      />
      <Stack
        justifyContent="space-between"
        background={colors.white}
        backgroundSize="cover"
        backgroundPosition="center"
        css={responsive({
          padding: { base: "28px 20px", lg: "60px" },
          borderRadius: { base: "8px", lg: "16px" },
          flexDirection: { base: "column", lg: "row" },
          gap: { base: "24px", lg: "36px" },
        })}
      >
        <VStack
          justifyContent="center"
          css={responsive({
            width: { base: "100%", lg: "50%" },
          })}
        >
          <ColorIcon
            name="icon-shield-check-mono"
            size={36}
            color={colors.brown300}
          />
          <Box
            className="subtitle_21_eb lg_head_32_b"
            color={colors.brown400}
            css={responsive({
              padding: { base: "8px 0", lg: "16px 0 40px" },
            })}
          >
            {secondContent.title}
          </Box>
          <Box
            className="body_14_r lg_subtitle_21_r"
            color={colors.brown400}
            css={{ wordBreak: "keep-all", whiteSpace: "pre-line" }}
          >
            {secondContent.description}
          </Box>
        </VStack>

        <VStack
          css={responsive({
            width: { base: "100%", lg: "50%" },
            height: { base: "100%", lg: "366px" },
          })}
          borderRadius="16px"
        >
          <Image
            src={getCdnImageSrc(`direct/home/about-des-${num}.png`)}
            width="100%"
            height="auto"
            alt=""
          />
        </VStack>
      </Stack>
    </VStack>
  );
}

function AboutMobileBackgroundItem({
  num,
  firstContent,
  secondContent,
}: {
  num: number;
  firstContent: { title: ReactNode; description: string };
  secondContent: { title: ReactNode; description: ReactNode };
}) {
  const { isBiggerThanLg } = useResponsive();
  return (
    <VStack gap="64px">
      <AboutFirstContentItem
        num={num}
        title={firstContent.title}
        description={firstContent.description}
      />
      <Stack
        justifyContent="space-between"
        css={responsive({
          padding: { base: "28px 20px", lg: "60px" },
          borderRadius: { base: "8px", lg: "16px" },
          flexDirection: { base: "column", lg: "row" },
          gap: { base: "24px", lg: "36px" },
          background: {
            base: `linear-gradient(258deg, rgba(255, 255, 255, 0.00) -60.52%, #FFF 75.45%),url(${getCdnImageSrc(
              `direct/home/about-des-${num}.png`
            )})`,
            lg: colors.white,
          },
        })}
        backgroundSize="cover"
        backgroundPosition="center"
      >
        <VStack
          justifyContent="center"
          css={responsive({
            width: { base: "100%", lg: "50%" },
          })}
        >
          <ColorIcon
            name="icon-shield-check-mono"
            size={36}
            color={colors.brown300}
          />
          <Box
            className="subtitle_21_eb lg_head_32_b"
            color={colors.brown400}
            css={responsive({
              padding: { base: "8px 0 21px", lg: "16px 0 40px" },
            })}
          >
            {secondContent.title}
          </Box>

          <Box
            className="body_14_r lg_subtitle_21_r"
            color={colors.brown400}
            css={{ wordBreak: "keep-all", whiteSpace: "pre-line" }}
          >
            {secondContent.description}
          </Box>
        </VStack>

        {isBiggerThanLg && (
          <VStack
            borderRadius="16px"
            css={responsive({
              width: { base: "100%", lg: "50%" },
              height: { base: "100%", lg: "510px" },
            })}
          >
            <Image
              src={getCdnImageSrc(`direct/home/about-des-${num}.png`)}
              width="100%"
              height="100px"
              alt=""
            />
          </VStack>
        )}
      </Stack>
    </VStack>
  );
}

function AboutBadgeDescription({
  num,
  description,
}: {
  num: number;
  description: string;
}) {
  const { isBiggerThanLg } = useResponsive();
  return (
    <HStack gap={isBiggerThanLg ? "16px" : "6px"} alignItems="center">
      <Box
        className="orange650"
        fontWeight={600}
        background={colors.orange250}
        borderRadius="5px"
        css={responsive({
          fontSize: { base: "9px", lg: "21px" },
          padding: { base: "1px 8px", lg: "6px 12px" },
        })}
      >
        0{num}
      </Box>
      <Box className="subtitle_14_sb lg_">{description}</Box>
    </HStack>
  );
}
